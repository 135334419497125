<template>
  <div v-if="profession" class="huge-container">
    <Header :showSearchButton="true"/>
    <div v-if="profession" class="main-container">
      <div class="center-container">
        <img v-if="profession.attributes && profession.attributes.image" :src="profession.attributes.image"
             alt="Profession Image" class="profession-image"/>
        <section class="profession-detail" v-if="profession.attributes && profession.attributes.identity">
          <!-- Navigation Back Link -->
          <a class="back" href="/professioni"><img style="width: 30px; transform: scaleX(-1); margin-right: 8px"
                                                   src="@/assets/slider_arrow.svg"/> FIGURE PROFESSIONALI</a>

          <div class="detail-header" :class="{ 'is-expanded': isFullDescriptionVisible }"
               v-if="profession.attributes && profession.attributes.identity">

            <h6 style="color: limegreen;margin-bottom: 3px">PROFESSIONI</h6>

            <div style="display: flex;flex-direction: row;gap: 10px; align-items: center;margin-top: 3px"
                 v-if="profession.attributes && profession.attributes.identity.title">
              <h1 class="profession-title">{{ profession.attributes.identity.title }}</h1>
              <InfoIcon v-if="profession.attributes.foglia_bianca" type="foglia_bianca_w"></InfoIcon>
              <InfoIcon v-if="profession.attributes.foglia_verde" type="foglia_verde_w"></InfoIcon>
            </div>
            <!-- Denomination Links (if any) -->
            <div v-if="profession.attributes.denominazione && profession.attributes.denominazione.length">
              <span v-for="(item, index) in profession.attributes.denominazione" :key="`denomination-${index}`">
                <a style="color: white">{{ item.title }}</a><span
                  v-if="index < profession.attributes.denominazione.length - 1">, </span>
              </span>
            </div>

            <!-- Profession Description with Read More/Less functionality -->
            <div class="profession-description" :class="{ 'is-description-expanded': isFullDescriptionVisible }"
                 v-if="profession.attributes && profession.attributes.identity.description"
                 v-html="profession.attributes.identity.description"></div>
            <button @click="toggleDescription" class="read-more-button"
                    v-if="profession.attributes && profession.attributes.identity.description">
              <a>{{ isFullDescriptionVisible ? 'Leggi meno' : 'Leggi Tutto' }}</a>
            </button>

            <!-- Related Professions Links (if any) -->
            <div
                v-if="profession.attributes.professioni_prossime && profession.attributes.professioni_prossime.data && profession.attributes.professioni_prossime.data.length">
              <h6 class="figure-prossimita">FIGURE DI PROSSIMITÀ</h6>
              <span v-for="(item, index) in profession.attributes.professioni_prossime.data"
                    :key="`proximity-${index}`">
                <a :href="`/professioni/${item.attributes.identifier}`" class="profession-link">{{
                    item.attributes.title
                  }}</a>
                <span v-if="index < profession.attributes.professioni_prossime.data.length - 1">, </span>
              </span>
            </div>

            <!-- Video Button (if related videos exist) -->
            <div class="video-e-percorso">

              <button
                  v-if="profession.attributes.related_videos && profession.attributes.related_videos.data && profession.attributes.related_videos.data.length"
                  @click="showVideos" class="video-button">
                <img src="@/assets/icon_screen_green.svg" class="video-icon" alt="Video Icon"/> VIDEO
              </button>


              <a @click="scrollToSection(-1)" class="percorso-btn">PERCORSO FORMATIVO</a>


            </div>

            <VideoPopupComponent
                :videoId="selectedVideoId"
                :isVisible="isPopupOpen"
                @close-popup="closePopup"
                @change-popup="changePopup"
            />
          </div>

          <!-- Detail Body -->
          <div class="detail-body">
            <div class="sticky-bar" v-if="profession" :class="{ visible: stickyVisible }">
              <div v-for="(section, index) in sectionTitles" :key="index" class="sticky-title"
                   :class="{ active: index === activeSection }" @click="scrollToSection(index)">
                {{ section }}
              </div>
            </div>


            <div class="sticky-bar-fixed" v-if="profession">
              <div v-for="(section, index) in sectionTitles" :key="index" class="sticky-title"
                   :class="{ active: index === activeSection }" @click="scrollToSection(index)">
                {{ section }}
              </div>
            </div>

            <div class="sections-detail">
              <!-- Norme -->
              <div id="norme"
                   v-if="profession.attributes && profession.attributes.identity && profession.attributes.identity.norme">
                <h2>{{ profession.attributes.identity.norme_title }}</h2>
                <div v-html="profession.attributes.identity.norme" class="richtext"></div>
              </div>

              <!-- EQF -->
              <div id="eqf"
                   v-if="profession.attributes && profession.attributes.identity && profession.attributes.identity.EQF">
                <h2>{{ profession.attributes.identity.EQF_title }}</h2>
                <div v-html="profession.attributes.identity.EQF" class="richtext"></div>
              </div>

              <!-- Che cosa fa -->
              <div id="che_cosa_fa"
                   v-if="profession.attributes && profession.attributes.che_cosa_fa && profession.attributes.che_cosa_fa.description">
                <h2>{{ profession.attributes.che_cosa_fa.title }}</h2>
                <div v-html="profession.attributes.che_cosa_fa.description" class="richtext"></div>
              </div>

              <!-- Dove lavora -->
              <div id="dove_lavora"
                   v-if="profession.attributes && profession.attributes.dove_lavora && profession.attributes.dove_lavora.description">
                <h2>{{ profession.attributes.dove_lavora.title }}</h2>
                <div v-html="profession.attributes.dove_lavora.description" class="richtext"></div>
              </div>

              <!-- Condizioni di lavoro -->
              <div id="condizioni_di_lavoro"
                   v-if="profession.attributes && profession.attributes.condizioni && profession.attributes.condizioni.description">
                <h2>{{ profession.attributes.condizioni.title }}</h2>
                <div v-html="profession.attributes.condizioni.description" class="richtext"></div>
              </div>

              <!-- Competenze -->
              <div id="attivita_compenteze"
                   v-if="profession.attributes && profession.attributes.attivita_compenteze && profession.attributes.attivita_compenteze.description">

                <div v-html="profession.attributes.attivita_compenteze.description" class="richtext"></div>
              </div>

              <!-- Attività e competenze - Conoscenze -->
              <div
                  v-if="profession.attributes && profession.attributes.attivita_compenteze && (profession.attributes.attivita_compenteze.conoscenze_specialistiche || profession.attributes.attivita_compenteze.conoscenze_generali)">
                <h1>{{ profession.attributes.attivita_compenteze.conoscenze_title }}</h1>

                <div v-if="profession.attributes.attivita_compenteze.conoscenze_specialistiche">
                  <h2>{{ profession.attributes.attivita_compenteze.conoscenze_specialistiche_title }}</h2>
                  <div v-html="profession.attributes.attivita_compenteze.conoscenze_specialistiche"
                       class="richtext"></div>
                </div>

                <div v-if="profession.attributes.attivita_compenteze.conoscenze_generali">
                  <h2>{{ profession.attributes.attivita_compenteze.conoscenze_generali_title }}</h2>
                  <div v-html="profession.attributes.attivita_compenteze.conoscenze_generali" class="richtext"></div>
                </div>
              </div>

              <!-- Attività e competenze - Abilità -->
              <div
                  v-if="profession.attributes && profession.attributes.attivita_compenteze && (profession.attributes.attivita_compenteze.abilita_specialistiche || profession.attributes.attivita_compenteze.abilita_generali) ">
                <h1>{{ profession.attributes.attivita_compenteze.abilita_title }}</h1>

                <div v-if="profession.attributes.attivita_compenteze.abilita_specialistiche">
                  <h2>{{ profession.attributes.attivita_compenteze.abilita_specialistiche_title }}</h2>
                  <div v-html="profession.attributes.attivita_compenteze.abilita_specialistiche" class="richtext"></div>
                </div>

                <div v-if="profession.attributes.attivita_compenteze.abilita_generali">
                  <h2>{{ profession.attributes.attivita_compenteze.abilita_generali_title }}</h2>
                  <div v-html="profession.attributes.attivita_compenteze.abilita_generali" class="richtext"></div>
                </div>
              </div>

              <!-- Attività e competenze - Comportamenti -->
              <div
                  v-if="profession.attributes && profession.attributes.attivita_compenteze && profession.attributes.attivita_compenteze.comportamenti && profession.attributes.attivita_compenteze.comportamenti.data && profession.attributes.attivita_compenteze.comportamenti.data.length">
                <h2>{{ profession.attributes.attivita_compenteze.comportamenti_title }}</h2>
                <div v-for="(item, index) in profession.attributes.attivita_compenteze.comportamenti.data"
                     :key="`course-${index}`">
                  <h4 style="color: darkslategray; font-size: 20px;line-height: 24px;font-family: 'MontserratMedium', sans-serif;font-weight: normal;">
                    {{ item.attributes.title }}</h4>
                  <div v-html="item.attributes.description" class="richtext"></div>
                </div>
              </div>

              <!-- Attività e competenze - Percorso formale -->
              <div
                  v-if="profession.attributes && profession.attributes.attivita_compenteze && profession.attributes.attivita_compenteze.percorso_formale">
                <h2 id="percorso-formativo">{{ profession.attributes.attivita_compenteze.percorso_formale_title }}</h2>
                <div v-html="profession.attributes.attivita_compenteze.percorso_formale" class="richtext"></div>
                <div
                    v-if="profession.attributes.attivita_compenteze.formale_courses && profession.attributes.attivita_compenteze.formale_courses.data && profession.attributes.attivita_compenteze.formale_courses.data.length">
                  <div v-for="(item, index) in profession.attributes.attivita_compenteze.formale_courses.data"
                       :key="`course-${index}`">
                    <a style="color: darkslategray" :href="`${item.attributes.link}`"
                       target="_blank">{{ item.attributes.title }}</a>
                  </div>
                </div>
              </div>

              <!-- Attività e competenze - Percorso consigliato -->
              <div
                  v-if="profession.attributes && profession.attributes.attivita_compenteze && profession.attributes.attivita_compenteze.percorso_consigliato">
                <h2>{{ profession.attributes.attivita_compenteze.percorso_consigliato_title }}</h2>
                <div v-html="profession.attributes.attivita_compenteze.percorso_consigliato" class="richtext"></div>
                <div
                    v-if="profession.attributes.attivita_compenteze.consigliato_courses && profession.attributes.attivita_compenteze.consigliato_courses.data && profession.attributes.attivita_compenteze.consigliato_courses.data.length">
                  <div v-for="(item, index) in profession.attributes.attivita_compenteze.consigliato_courses.data"
                       :key="`course-${index}`">
                    <a style="color: darkslategray" :href="`${item.attributes.link}`"
                       target="_blank">{{ item.attributes.title }}</a>
                  </div>
                </div>
              </div>

              <!-- Attività e competenze - Carriera -->
              <div
                  v-if="profession.attributes && profession.attributes.attivita_compenteze && profession.attributes.attivita_compenteze.carriera_title">
                <h2>{{ profession.attributes.attivita_compenteze.carriera_title }}</h2>
                <div v-html="profession.attributes.attivita_compenteze.carriera" class="richtext"></div>
              </div>

              <!-- Informazioni -->
              <div id="informazioni" v-if="profession.attributes && profession.attributes.informazioni">
                <h2>{{ profession.attributes.informazioni.title }}</h2>
                <div v-html="profession.attributes.informazioni.description" class="richtext"></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    <!-- Correlations -->
    <div class="correlations"
         v-if="profession.attributes.professioni_correlate.data.length || profession.attributes.aree_professionali_correlate.data.length || profession.attributes.corsi_di_laurea.data.length">
      <h2>Guarda anche</h2>
      <div class="correlations-row">
        <!-- Professioni Correlate -->
        <div class="correlation-block" v-if="profession.attributes.professioni_correlate.data.length>0">
          <h3>Professioni Correlate</h3>
          <div v-for="(profession, index) in profession.attributes.professioni_correlate.data"
               :key="`profession-${index}`"
               class="correlation-link">
            <a :href="`/professioni/${profession.attributes.identifier}`">{{ profession.attributes.title }}</a>
            <div style="display: flex; flex-direction: row; gap: 20px">
              <InfoIcon v-if="profession.attributes.hasVideos" type="related_videos"></InfoIcon>
              <InfoIcon v-if="profession.attributes.foglia_bianca" type="foglia_bianca_w"></InfoIcon>
              <InfoIcon v-if="profession.attributes.foglia_verde" type="foglia_verde_w"></InfoIcon>
            </div>
          </div>
        </div>
        <!-- Aree Professionali Correlate -->
        <div class="correlation-block" v-if="profession.attributes.aree_professionali_correlate.data.length>0">
          <h3>Aree Professionali Correlate</h3>
          <div v-for="(area, index) in profession.attributes.aree_professionali_correlate.data" :key="`area-${index}`"
               class="correlation-link">
            <a :href="`/aree-professionali/${area.id}`">{{ area.attributes.title }}</a>
          </div>
        </div>
        <!-- Corsi Correlati -->
        <div class="correlation-block" v-if="filteredCourses(profession.attributes.corsi_di_laurea.data).length>0">
          <h3>Job Placement</h3>
          <div v-for="(course, index) in filteredCourses(profession.attributes.corsi_di_laurea.data)"
               :key="`course-${index}`"
               class="correlation-link">
            <a :href="course.attributes.job_placement.data.attributes.link"
               target="_blank">{{ course.attributes.title }}</a>
          </div>
        </div>

      </div>
    </div>

    <AppBottomComponent v-if="profession"/>
    <Footer v-if="profession"/>
  </div>
</template>


<script>
import Header from '@/components/General/AppHeader.vue';
import Footer from '@/components/General/AppFooter.vue';
import AppBottomComponent from '@/components/General/AppBottomComponent.vue';
import VideoPopupComponent from '@/components/VideosPage/VideoPopupComponent.vue';
import {fetchSingleProfession} from "@/services/apiService";
import InfoIcon from "@/components/General/InfoIcon.vue";

export default {
  components: {
    Header,
    Footer,
    AppBottomComponent,
    VideoPopupComponent,
    InfoIcon
  },
  data() {
    return {
      profession: null,
      isFullDescriptionVisible: false,
      stickyVisible: false,
      activeSection: 0,
      sectionTitles: [],
      isPopupOpen: false,
      selectedVideoId: null,
      pageMetaTitle: '',
      pageMetaDescription: '',
    };
  },
  async created() {
    try {
      console.log(this.$route.params.identifier)
      const data = await this.loadProfessionsData(this.$route.params.identifier);

      this.profession = data;

      this.pageMetaTitle = this.profession.attributes.meta_title || this.profession.attributes.title;
      this.pageMetaDescription = this.profession.attributes.meta_description || this.profession.attributes.identity.description;
      if (this.profession && this.profession.attributes && this.profession.attributes.identity && this.profession.attributes.identity.title) {
        document.title = `${this.profession.attributes.identity.title}`;
      }
      this.setMetaTags();
    } catch (error) {
      console.error('Error loading profession data:', error);
    }
    window.addEventListener('scroll', this.updateActiveSection);
    window.addEventListener('scroll', this.stickyBarVisibility);
    await this.initializeSectionTitles();
  },
  computed: {
    pageURL() {
      return window.location.origin + window.location.pathname;
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateActiveSection);
    window.removeEventListener('scroll', this.stickyBarVisibility);
    this.removeMetaTags();
  },
  methods: {
    async loadProfessionsData(identifier) {
      try {
        return await fetchSingleProfession(identifier);
      } catch (error) {
        console.error('Error fetching profession data:', error);
        throw error;
      }
    },
    setMetaTags() {
      document.title = this.pageMetaTitle;

      const metaTags = [
        {name: 'title', content: this.pageMetaTitle},
        {name: 'description', content: this.pageMetaDescription},
        {property: 'og:title', content: this.pageMetaTitle},
        {property: 'og:url', content: this.pageURL},
        {property: 'og:type', content: 'website'},
        {property: 'og:description', content: this.pageMetaDescription},
        {property: 'twitter:title', content: this.pageMetaTitle},
        {property: 'twitter:url', content: this.pageURL},
        {property: 'twitter:description', content: this.pageMetaDescription}
      ];

      metaTags.forEach(tagDef => {
        const tag = document.createElement('meta');
        Object.keys(tagDef).forEach(key => {
          tag.setAttribute(key, tagDef[key]);
        });
        tag.setAttribute('data-vue-meta', 'true');
        document.head.appendChild(tag);
      });
    },
    removeMetaTags() {
      const tags = document.querySelectorAll('[data-vue-meta="true"]');
      tags.forEach(tag => tag.parentNode.removeChild(tag));
    },

    toggleDescription() {
      this.isFullDescriptionVisible = !this.isFullDescriptionVisible;
    },
    scrollToSection(index) {
      const stickyBarElement = document.querySelector('.sticky-bar-fixed');
      let sectionId;
      if (index >= 0 && index < this.sectionTitles.length) {
        sectionId = this.sectionTitles[index].toLowerCase().replace(/\s+/g, '_');
      }
      if (this.sectionTitles[index] === "Compenteze") {
        sectionId = "attivita_compenteze";
      }
      if (index == -1) {
        sectionId = "percorso-formativo";
      }
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        const offset = stickyBarElement.offsetHeight;
        const sectionTop = sectionElement.offsetTop - (1.1 * offset);
        window.scrollTo({top: sectionTop, behavior: 'smooth'});
      }
    },

    updateActiveSection() {
      let closestSectionIndex = 0;
      let smallestDifference = Infinity;
      this.sectionTitles.forEach((title, index) => {
        let sectionId = title.toLowerCase().replace(/\s+/g, '_');
        if (title === "Compenteze") {
          sectionId = "attivita_compenteze";
        }

        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          const difference = Math.abs(sectionElement.getBoundingClientRect().top);
          if (difference < smallestDifference) {
            closestSectionIndex = index;
            smallestDifference = difference;
          }
        }
      });
      this.activeSection = closestSectionIndex;
    },
    stickyBarVisibility() {
      const fixedBarElement = document.querySelector('.sticky-bar-fixed'); // Adjust if you have a specific header element
      const stickyBarElement = document.querySelector('.sticky-bar');
      if (fixedBarElement && stickyBarElement) {
        const headerBottom = fixedBarElement.offsetHeight + fixedBarElement.offsetTop;
        this.stickyVisible = window.scrollY > headerBottom;
      }
    },
    showVideos() {
      console.log(this.profession.attributes.related_videos.data[0])
      this.selectedVideoId = this.profession.attributes.related_videos.data[0].id;
      this.isPopupOpen = true;
    },
    openPopup(videoId) {
      this.selectedVideoId = videoId; // Now storing video ID
      this.isPopupOpen = true;
    },
    changePopup(videoId) {
      this.closePopup();
      this.selectedVideoId = videoId; // Handling video ID directly
      this.isPopupOpen = true;
    },
    closePopup() {
      this.selectedVideoId = null; // Clear selected video ID
      this.isPopupOpen = false;
    },
    async initializeSectionTitles() {
      const initialSections = ["Norme", "Che Cosa Fa", "Dove Lavora", "Condizioni di lavoro", "Compenteze", "Informazioni"];
      this.sectionTitles = initialSections.filter(section => {
        let sectionId = section.toLowerCase().replace(/\s+/g, '_');

        if (section === "Compenteze") {
          sectionId = "attivita_compenteze"
        } else if (section === "Condizioni di lavoro") {
          sectionId = "condizioni";
        }

        console.log(sectionId);

        if (sectionId === "norme") {
          return this.profession.attributes.identity.norme;
        } else {
          console.log(this.profession.attributes[sectionId])
          return this.profession.attributes && this.profession.attributes[sectionId] && this.profession.attributes[sectionId].description;
        }

      });
      console.log('kajsndkajs')
      console.log(this.sectionTitles)
    },
    filteredCourses(courses) {
      return courses.filter(course =>
          course.attributes.job_placement &&
          course.attributes.job_placement.data &&
          course.attributes.job_placement.data.attributes.link
      );
    },
  },

};
</script>


<style scoped>
/* Layout containers */
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  overflow-x: hidden;
  transition: all 0.5s ease;
}

.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 75%;
  margin-top: 50px;
  transition: all 0.5s ease;
}

.profession-detail {
  display: flex;
  flex-direction: column;
  width: 90%;
}

/* Detail header styling */
.detail-header {
  padding: 70px;
  background: #013747; /* Dark background for header */
  color: whitesmoke; /* Light text color for contrast */
  z-index: 5;
  width: 40%;
  transition: all 0.5s ease;
}


.is-expanded {
  width: 70%;
}

.detail-body {
  padding: 20px;
  width: 100%;
  margin-top: 50px;
  transition: all 0.5s ease;
}


/* Description visibility toggle */
.profession-description {
  overflow: hidden; /* Hide overflow when description is collapsed */
  max-height: 280px;
  position: relative;
  margin-top: 30px;
  width: 100%;
  transition: all 0.5s ease;
}

.is-description-expanded {
  max-height: 1500px; /* Remove max-height when expanded to show full content */
}


.profession-description:after {

  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  width: 30%;
  height: 2px; /* The thickness of your border */
  background: linear-gradient(to left, rgba(50, 205, 50, 0), rgba(50, 205, 50, 0.6), rgba(50, 205, 50, 1) 80%, rgba(50, 205, 50, 0.5), rgba(50, 205, 50, 0));
  border-radius: 10px 10px 0 0; /* Only the top corners rounded */
}

/* Read more button styling */
.read-more-button {
  background-color: transparent;

  border: none;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;

}

.read-more-button a {
  color: whitesmoke; /* Match theme color */
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

.read-more-button a:hover {
  border-bottom: 1px solid lime;
}

.figure-prossimita {
  color: lime;
  margin-top: 45px;
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}

/* Profession image and video button styling */
.profession-image {
  position: absolute;
  height: 720px;
  top: 170px;
  right: -200px;
  z-index: 1;
  width: 70vw;
  object-fit: cover; /* Ensure the image covers the area properly */
  transition: all 0.5s ease;
}

.video-button {
  background-color: #013747; /* Dark background for consistency with header */
  color: white;
  border: 2px solid limegreen; /* Highlight with theme color */
  padding-left: 20px;
  padding-right: 40px;
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 35px; /* Space between icon and text */
  height: 55px;
  max-width: 350px;
  text-align: center;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 1.5px;
  position: relative;
  font-family: "MontserratSemiBold";
}

.video-icon {
  width: 24px; /* Adjust icon size for visibility */
  height: 24px; /* Maintain aspect ratio */
}

.back {
  display: flex;
  align-items: center;
  padding: 10px 0 30px;
  text-decoration: none;
  color: inherit; /* Use parent's text color */
  font-size: 12px;
  font-stretch: 400%;
}


.back:hover {
  transform: translateX(-10px);
}

.profession-link {
  color: white; /* Links contrast against dark background */
}

.profession-link:hover {
  text-decoration: underline; /* Visual feedback on hover */
  color: #CCCCCC; /* Lighter shade for hover state */
}

.sticky-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f9f9f9;
  z-index: 200;
  align-items: center;
  justify-content: center;
  display: none;
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  color: #495057;
}

.profession-title {
  margin-top: 5px;
}

.sticky-bar.visible {
  display: flex; /* Show when visible */
}

.sticky-title {
  margin: 0 8px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 20px;
  color: #013747;
  width: auto;
  white-space: nowrap;
  letter-spacing: 1px;
  font-weight: 400;
}

.sticky-title.active {
  font-weight: 700;
  color: limegreen; /* Highlight the active section */
}

.sticky-bar-fixed {
  width: 85%;
  z-index: 200;
  display: flex;
  align-items: flex-start;
  padding: 30px 0;
  border-bottom: 1px solid #ccc;
  font-family: "Montserrat", sans-serif;
  color: #495057;
  margin-bottom: 50px;
  overflow-x: auto;
}

.sections-detail {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.sections-detail h2 {
  font-family: "IBMPlexSans";
  color: #013747;
}


.correlations {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align to the left for consistency */
  justify-content: center;
  background: limegreen; /* Distinctive background color */
  width: 100%;
  padding: 10px;
  box-sizing: border-box; /* Ensures padding is included in width */
}

.correlations h2 {
  color: white;
  font-size: 48px; /* Large font size for section titles */
  margin-bottom: 20px; /* Spacing after the section title */
  margin-left: 210px; /* Align text with the content below */
  font-family: "IBMPlexSans", sans-serif; /* Consistent font family */
}

.correlations-row {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap in smaller views */
  margin-left: 215px; /* Left margin to align with the section title */
  margin-right: 170px; /* Left margin to align with the section title */
  margin-bottom: 80px; /* Bottom margin for spacing */
  justify-content: flex-start; /* Aligns items to the start */
  gap: 35px; /* Space between blocks */
}

.correlation-block {
  flex: 1 1 calc(50% - 20px); /* Flex basis adjusted for two columns */
  display: flex;
  flex-direction: column;
  max-width: calc(50% - 20px); /* Maximum width for two columns layout */
}

.correlation-block h3 {
  color: #013747; /* Dark color for contrast on lime background */
  font-size: 36px;
  font-family: "IBMPlexSans", sans-serif; /* Ensuring font consistency */
  letter-spacing: -0.6px;
  line-height: 29px;
  margin-bottom: 65px; /* Space below header */
  margin-top: 60px;
  padding-top: 20px;
  border-top: 1px solid #013747; /* Border style */
  padding-bottom: 5px; /* Padding for the bottom border */
}

.correlation-link {
  color: white; /* Links contrast against background */
  text-decoration: none; /* Removes underline */
  font-size: 16px; /* Legible font size */
  display: flex; /* Ensures full width for easier clickability */
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  font-family: "Montserrat", sans-serif; /* Consistent font family */
  margin-bottom: 20px;
  border-bottom: dashed 1px hsl(0deg, 0%, 100%);
  padding-bottom: 10px;
}

.correlation-link a {
  color: white; /* Links contrast against background */
  text-decoration: none; /* Removes underline */
  font-size: 16px; /* Legible font size */
  margin-bottom: 15px; /* Spacing between links */
  display: block; /* Ensures full width for easier clickability */
  font-family: "Montserrat", sans-serif; /* Consistent font family */
}

.correlation-link a:hover {
  color: #013747;
  font-weight: bold;
}

.percorso-btn {
  color: hsl(0deg, 0%, 100%);
  display: inline-flex;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 19px;
  letter-spacing: 1.5px;
  position: relative;
  font-family: "MontserratSemiBold";
  width: auto;
  cursor: pointer;
}

.percorso-btn:after {
  content: "";
  border-bottom: solid 1px #00CC00;
  height: 1px;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: block;
}

.video-e-percorso {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.back {
  font-family: "MontserratSemiBold", sans-serif;
  font-size: 11px;
  line-height: 19px;
  color: #013747;
  letter-spacing: 0.9px;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: transform 0.2s ease;
  margin-bottom: 30px;
}

table {
  font-weight: bold; /* Makes the first column text bold */
  border: solid 2px black;
}


@media (max-width: 1600px) {
  .detail-header {
    width: 50%;
  }


}

/* Medium devices (landscape phones, less than 768px) */
@media (max-width: 1300px) {
  .detail-header {
    width: 55%;
  }

  .center-container {
    width: 90%;
  }

  .profession-image {
    right: -120px;
  }
}


@media (max-width: 992px) {
  .video-e-percorso {
    flex-direction: column;
    gap: 50px;
    align-items: flex-start;
  }

  .back {
    display: none;
  }

  .profession-detail {
    width: 80%;
  }

  .profession-image {
    max-height: 200px;
    position: static;
    width: 100%;
    z-index: 1;
    object-fit: cover; /* Ensure the image covers the area properly */
  }

  .center-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-top: 25px;
  }

  .detail-header {
    padding: 40px;
    background: #013747; /* Dark background for header */
    color: whitesmoke; /* Light text color for contrast */
    z-index: 5;
    width: 100%;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between the links as needed */
  }

  .flex-container a {
    color: white; /* This ensures the text color is white */
    margin: 5px; /* Adds some space around each link */
    text-decoration: none; /* Removes underline from links */
  }

  .sticky-bar-fixed {
    width: 100%;
  }

  .sticky-bar {
    overflow-x: auto;
    align-items: flex-start;
    justify-content: left;
  }

  .richtext {
    overflow-x: auto;
  }

  .richtext table {
    width: 100%;
    table-layout: fixed;
  }

  .richtext table th {
    font-weight: bold; /* Makes text bold in table headers */
  }

  .richtext table, .richtext th, .richtext td {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sticky-bar-fixed, .sticky-bar-fixed * {
    box-sizing: border-box;
  }

  .correlations h2 {
    margin-left: 10px;

  }

  .correlations-row {
    margin-left: 15px; /* Left margin to align with the section title */
  }

  .correlation-block {
    width: 100%; /* Full width on smaller screens */
    max-width: 90%;
  }


  .correlations-row {
    flex-direction: column; /* Stack blocks vertically on smaller screens */
    width: 100%;
    margin-left: 10px;
  }

  .correlation-link a {

    font-size: clamp(12px, 1vw, 14px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Number of lines you want the text to be clamped at */
    -webkit-box-orient: vertical;
  }

  .sections-detail h2 {
    font-size: 28px;
    letter-spacing: -0.6px;
    line-height: 29px;
  }
}


</style>

